// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.mjs";
import * as Clerk from "./utils/Clerk.mjs";
import * as React from "react";
import * as Button from "../components/ui/Button.mjs";
import * as RelayEnv from "./RelayEnv.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as RouteDeclarations from "./routes/__generated__/RouteDeclarations.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as ClerkReact from "@clerk/clerk-react";
import * as ReactDOMExperimental from "rescript-relay/src/ReactDOMExperimental.mjs";
import * as RelayRouter__AssetPreloader from "rescript-relay-router/src/RelayRouter__AssetPreloader.mjs";

function EntryClient$SignedOut(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ClerkReact.SignInButton, {
                    mode: "modal",
                    children: Caml_option.some(JsxRuntime.jsx(Button.make, {
                              size: "lg",
                              children: "Sign In"
                            }))
                  }),
              className: "w-screen h-screen grid place-items-center"
            });
}

var SignedOut = {
  make: EntryClient$SignedOut
};

function EntryClient$EntryPoint(props) {
  var match = ClerkReact.useAuth();
  var getToken = match.getToken;
  React.useEffect((function () {
          Clerk.Hooks.Auth.getTokenRef.contents = getToken;
        }), [getToken]);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ClerkReact.SignedOut, {
                      children: JsxRuntime.jsx(EntryClient$SignedOut, {})
                    }),
                JsxRuntime.jsx(ClerkReact.SignedIn, {
                      children: JsxRuntime.jsx(App.make, {
                            environment: props.environment,
                            routerContext: props.routerContext
                          })
                    })
              ]
            });
}

var EntryPoint = {
  make: EntryClient$EntryPoint
};

function boot() {
  var routes = RouteDeclarations.make(undefined);
  var routerEnvironment = RelayRouter.RouterEnvironment.makeBrowserEnvironment();
  var match = RelayRouter.Router.make(routes, routerEnvironment, RelayEnv.environment, RelayRouter__AssetPreloader.makeClientAssetPreloader(RelayEnv.preparedAssetsMap));
  ReactDOMExperimental.renderConcurrentRootAtElementWithId(JsxRuntime.jsx(ClerkReact.ClerkProvider, {
            publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
            children: JsxRuntime.jsx(EntryClient$EntryPoint, {
                  environment: RelayEnv.environment,
                  routerContext: match[1]
                })
          }), "root");
}

boot();

export {
  SignedOut ,
  EntryPoint ,
  boot ,
}
/*  Not a pure module */
