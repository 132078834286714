// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  if (str === "language" || str === "duration" || str === "displayName" || str === "uploadedAt") {
    return str;
  }
  
}

function serialize(s) {
  return s;
}

export {
  parse ,
  serialize ,
}
/* No side effect */
