// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  if (str === "asc" || str === "desc") {
    return str;
  }
  
}

function serialize(s) {
  return s;
}

export {
  parse ,
  serialize ,
}
/* No side effect */
