// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Sonner from "../components/ui/Sonner.mjs";
import * as Tooltip from "../components/ui/Tooltip.mjs";
import * as Core__Null from "@rescript/core/src/Core__Null.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as ErrorBoundary from "./components/ErrorBoundary.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as ClerkReact from "@clerk/clerk-react";
import * as PendingIndicatorBar from "./components/PendingIndicatorBar.mjs";
import * as WebAnalytics from "@statsig/web-analytics";
import * as ReactBindings from "@statsig/react-bindings";
import * as SessionReplay from "@statsig/session-replay";

function App(props) {
  var match = ClerkReact.useAuth();
  var orgRole = match.orgRole;
  var orgId = match.orgId;
  var orgSlug = match.orgSlug;
  var userId = match.userId;
  var match$1 = ClerkReact.useUser();
  var user = match$1.user;
  var api = import.meta.env.VITE_STATSIG_API_URL;
  var tmp = api === "" ? undefined : api;
  var options_environment = {
    tier: import.meta.env.VITE_STATSIG_ENVIRONMENT
  };
  var options_networkConfig = {
    api: tmp
  };
  var options = {
    environment: options_environment,
    disableLogging: import.meta.env.VITE_STATSIG_DISABLE_LOGGING,
    disableStorage: import.meta.env.VITE_STATSIG_DISABLE_STORAGE,
    networkConfig: options_networkConfig
  };
  var getUserObject = function () {
    return {
            userID: userId,
            email: Core__Null.fromOption(user !== undefined ? user.primaryEmailAddress.emailAddress : undefined),
            custom: {
              organizationRole: orgRole
            },
            customIDs: {
              organizationID: orgId,
              organizationSlug: orgSlug
            }
          };
  };
  var match$2 = ReactBindings.useClientAsyncInit(import.meta.env.VITE_STATSIG_SDK_CLIENT_KEY, getUserObject(), options);
  var client = match$2.client;
  React.useEffect((function () {
          WebAnalytics.runStatsigAutoCapture(client);
          SessionReplay.runStatsigSessionReplay(client);
        }), [client]);
  return JsxRuntime.jsx(ReactBindings.StatsigProvider, {
              client: client,
              children: JsxRuntime.jsx(Tooltip.Provider.make, {
                    children: Caml_option.some(JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
                              environment: props.environment,
                              children: JsxRuntime.jsx(RelayRouter.Provider.make, {
                                    value: props.routerContext,
                                    children: JsxRuntime.jsx(React.Suspense, {
                                          children: Caml_option.some(JsxRuntime.jsxs(ErrorBoundary.make, {
                                                    children: [
                                                      JsxRuntime.jsx(RelayRouter.RouteRenderer.make, {
                                                            renderPending: (function (pending) {
                                                                return JsxRuntime.jsx(PendingIndicatorBar.make, {
                                                                            pending: pending
                                                                          });
                                                              })
                                                          }),
                                                      JsxRuntime.jsx(Sonner.Toaster.make, {})
                                                    ],
                                                    fallback: (function (param) {
                                                        return JsxRuntime.jsx("div", {
                                                                    children: "Error!"
                                                                  });
                                                      })
                                                  })),
                                          fallback: "Loading..."
                                        })
                                  })
                            }))
                  })
            });
}

var make = App;

export {
  make ,
}
/* react Not a pure module */
