// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  if (str === "IMPROVED" || str === "STANDARD") {
    return str;
  }
  
}

function serialize(s) {
  return s;
}

var defaultValue = "STANDARD";

export {
  defaultValue ,
  parse ,
  serialize ,
}
/* No side effect */
